import React from 'react'
import styled from 'styled-components'

import { H1 } from '../shared/text'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;

  ${media.mobile} {
    padding: 150px 20px 80px 20px;
  }
  ${media.tablet} {
    padding: 220px 50px 100px 50px;
  }
  ${media.desktop} {
    padding: 250px 40px 120px 40px;
  }

  & ul {
    padding: 0 0 0 16px;
  }
`

const Gradient = styled.div`
  background: linear-gradient(209.03deg, #838fc5 -3.44%, rgba(126, 243, 157, 0.34) 27.41%, rgba(255, 255, 255, 0) 37.96%);
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  & h1 {
    margin-bottom: 31px;
  }
  & h2 {
    margin: 40px 0 24px 0;
  }
  & p {
    margin-bottom: 30px;
  }
  & a {
    font-weight: 700;
  }

  ${media.mobile} {
    max-width: 95%;
  }
  ${media.tablet} {
    max-width: 600px;
  }
  ${media.desktop} {
    max-width: 945px;
  }
`

interface LegalViewProps {
  title: string
}

const LegalView: React.FC<LegalViewProps> = ({ title, children }) => (
  <Section>
    <Gradient />
    <Container>
      <H1>{title}</H1>
      {children}
    </Container>
  </Section>
)

export default LegalView
