import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import LegalView from './LegalView'
import { H2, P } from '../shared/text'

const Privacy = () => {
  return (
    <LegalView title="Privacy Policy">
      <P>
        Thank you for visiting our web site. This privacy policy tells you how we use personal information collected at this site. Please
        read this privacy policy before using the site or submitting any personal information. By using the site, you are accepting the
        practices described in this privacy policy. These practices may be changed, but any changes will be posted and changes will only
        apply to activities and information on a going forward, not retroactive basis. You are encouraged to review the privacy policy
        whenever you visit the site to make sure that you understand how any personal information you provide will be used.
      </P>

      <P>
        Note: the privacy practices set forth in this privacy policy are for this web site only. If you link to other web sites, please
        review the privacy policies posted at those sites.
      </P>

      <H2>Collection of Information</H2>

      <P>
        We collect personally identifiable information, like names, postal addresses, email addresses, phone numbers, etc., when voluntarily
        submitted by our visitors. The information you provide is used to fulfill your specific request. This information is only used to
        fulfill your specific request, unless you give us permission to use it in another manner, for example to add you to one of our
        mailing lists. By providing my phone number, I consent to receive automated marketing by text message from this company through an
        automated telephone dialing system at the number provided. Message/data rates may apply and you can unsubscribe anytime. For text
        messages, you can text “STOP” in response to a text message that you receive. You may at any time request that Grummies delete any
        data you have previously provided through any means, including but not limited to via email, consultation or API integration.
      </P>

      <H2>Cookie / Tracking Technology</H2>

      <P>
        The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for
        gathering information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how
        visitors use the Site. Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies
        and other tracking technology, however, if you previously provided personally identifiable information, cookies may be tied to such
        information. Aggregate cookie and tracking information may be shared with third parties.
      </P>

      <P>
        The Site also collects potentially personally-identifying information that is collected by Google Analytics. The analytics feature
        The Site has implemented is based on Display Advertising (e.g., Remarketing, Google Display Network Impression Reporting, the
        Doubleclick Campaign Manager integration, or Google Analytics Demographics and Interest Reporting). Visitors can opt-out of Google
        Analytics for Display Advertising and customer Display Network ads using Ads Settings.
      </P>

      <P>
        The Site will not facilitate the merging of personally-identifiable information with non-personally identifiable information
        previously collected from Google Display Advertising features. The Site also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses. The Site only discloses logged in user IP addresses under the same circumstances that it uses
        and discloses personally-identifying information as described below.
      </P>

      <P>At any time, visitors may choose to opt-out of Google Analytics tracking with the Google Analytics opt-out browser add-on.</P>

      <P>
        We also use Google Analytics Demographics and Interest Reporting feature to identify trends in the usage of its website which may be
        published in reports for internal use.
      </P>

      <H2>Distribution of Information</H2>

      <P>
        We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do
        so when: (1) permitted or required by law; or, (2) trying to protect against or prevent actual or potential fraud or unauthorized
        transactions; or, (3) investigating fraud which has already taken place. The information is not provided to these companies for
        marketing purposes.
      </P>

      <H2>Commitment to Data Security</H2>

      <P>
        Your personally identifiable information is kept secure. Only authorized employees, agents and contractors (who have agreed to keep
        information secure and confidential) have access to this information. All emails and newsletters from this site allow you to opt out
        of further mailings.
      </P>

      <H2>Privacy Contact Information</H2>

      <P>
        If you have any questions, concerns, or comments about our privacy policy you may contact us using the information below: by e-mail:{' '}
        <OutboundLink href="mailto:support@eatyourgrummies.com" target="_blank" rel="noopener noreferrer">
          support@eatyourgrummies.com
        </OutboundLink>{' '}
        or by mail: Eat Your Gummies, LLC, 100 Crosby St Suite 506, New York, NY 10012. We reserve the right to make changes to this policy.
        Any changes to this policy will be posted.
      </P>
    </LegalView>
  )
}

export default Privacy
