import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../layouts'
import Privacy from '../components/Legal/Privacy'
import SEO from '../components/shared/SEO'

const PrivacyPage = (props: PageProps) => {
  const { location } = props

  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout location={location}>
        <Privacy />
      </Layout>
    </>
  )
}

export default PrivacyPage
